var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Добро пожаловать в SalesFinder!",
      "width": "1000",
      "title-position": "center",
      "wide": false,
      "no-close": _vm.noClose
    }
  }, [_vm.activated ? _c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "welcome-subtitle"
  }, [_vm._v(" Ваш тариф успешно активирован, приятной работы! ")]), _c('button', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Начать работу")]), _c('a', {
    staticClass: "welcome-block",
    attrs: {
      "href": "https://help.salesfinder.ru",
      "target": "_blank"
    }
  }, [_c('svg', {
    staticClass: "welcome-icon",
    attrs: {
      "viewBox": "0 0 48 48",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    staticClass: "fill-000000",
    attrs: {
      "d": "M23 48.049a.996.996 0 0 1-.43-.097l-21-10a1 1 0 0 1-.57-.903v-31a1.002 1.002 0 0 1 1.43-.903L23 14.941l20.57-9.796a1 1 0 0 1 1.43.904v31a1 1 0 0 1-.57.903l-21 10a.996.996 0 0 1-.43.097zM3 36.417l20 9.524 20-9.524V7.633l-19.57 9.319a1.002 1.002 0 0 1-.859 0L3 7.633v28.784z",
      "fill": "#9eabd6"
    }
  }), _c('path', {
    staticClass: "fill-000000",
    attrs: {
      "d": "M23 12.204 5.567 3.903a1 1 0 0 1 .86-1.807L23 9.989l16.573-7.893a1 1 0 0 1 .86 1.807L23 12.204zM22 16.049h2v31h-2z",
      "fill": "#9eabd6"
    }
  })]), _c('p', {
    staticClass: "welcome-text"
  }, [_vm._v(" Для быстрого знакомства с функционалом сервиса мы подготовили для вас набор обучающих материалов. ")]), _c('svg', {
    staticClass: "welcome-arrow",
    attrs: {
      "enable-background": "new 0 0 32 32",
      "height": "32px",
      "id": "Слой_1",
      "version": "1.1",
      "viewBox": "0 0 32 32",
      "width": "32px",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z",
      "fill": "#121313",
      "fill-rule": "evenodd",
      "id": "Chevron_Right"
    }
  })])]), _c('router-link', {
    staticClass: "welcome-block",
    attrs: {
      "to": '/contact'
    }
  }, [_c('svg', {
    staticClass: "welcome-icon",
    attrs: {
      "fill": "none",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    staticClass: "fill-212121",
    attrs: {
      "d": "M9.25 7.307C9.09 7.491 9 7.745 9 8a.5.5 0 0 1-1 0c0-.473.161-.97.5-1.354C8.847 6.252 9.36 6 10 6s1.153.252 1.5.646c.339.385.5.88.5 1.354 0 .49-.116.87-.302 1.19-.163.279-.376.5-.545.677l-.042.043c-.186.195-.329.354-.434.554-.1.191-.177.444-.177.829a.5.5 0 1 1-1 0c0-.532.11-.947.291-1.293.177-.337.41-.584.598-.781l.022-.023c.188-.197.322-.337.423-.51.095-.163.166-.369.166-.686 0-.255-.089-.51-.25-.693C10.597 7.134 10.36 7 10 7s-.597.134-.75.307Zm.75 6.905a.7.7 0 1 0 0-1.399.7.7 0 0 0 0 1.4ZM2 10a8 8 0 1 1 4.262 7.075l-3.64.91a.5.5 0 0 1-.607-.606l.91-3.641A7.968 7.968 0 0 1 2 10Zm8-7a7 7 0 0 0-6.107 10.425.5.5 0 0 1 .05.366l-.756 3.022 3.022-.756a.5.5 0 0 1 .366.05A7 7 0 1 0 10 3Z",
      "fill": "#9eabd6"
    }
  })]), _c('p', {
    staticClass: "welcome-text"
  }, [_vm._v(" Если у вас есть какие-либо вопросы по работе сервиса, обязательно напишите нам. Мы всегда рады помочь! ")]), _c('svg', {
    staticClass: "welcome-arrow",
    attrs: {
      "enable-background": "new 0 0 32 32",
      "height": "32px",
      "id": "Слой_1",
      "version": "1.1",
      "viewBox": "0 0 32 32",
      "width": "32px",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z",
      "fill": "#121313",
      "fill-rule": "evenodd",
      "id": "Chevron_Right"
    }
  })])])], 1) : _c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "welcome-subtitle"
  }, [_vm._v(" Для бесплатной активации тарифа остался всего 1 шаг: "), _c('br'), _c('b', [_vm._v("введите api-токен вашего магазина на WB или Ozon")])]), _c('div', {
    staticClass: "welcome-two-columns"
  }, [_c('div', [_c('span', {
    staticClass: "modal-right__title"
  }, [_vm._v("Инструкция по подключению")]), _c('p', {
    staticClass: "modal-right__text"
  }, [_vm._v(" Для подключения и работы с вашим магазином в SalesFinder вам потребуется API токен с определенным типом и разрешенными методами. "), _c('br'), _c('br'), _c('b', [_vm._v("Для Wildberries")]), _c('br'), _c('br'), _vm._v(" Используйте токен с включенными методами: Статистика + Аналитика + Цены и скидки, токен обязательно должен быть без пометки «Только на чтение»! ")]), _c('p', {
    staticClass: "modal-right__text"
  }, [_c('b', [_vm._v("Для Ozon")]), _c('br'), _c('br'), _vm._v(" Используйте токен с типом Admin, ключ обязательно должен быть без пометки «Только чтение (Read Only)»! ")]), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "1fr 1fr",
      "width": "100%"
    }
  }, [_c('img', {
    staticClass: "modal-right__img",
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": require('@/assets/images/repricer/wildberries.jpg'),
      "alt": "New Token"
    }
  }), _c('img', {
    staticClass: "modal-right__img",
    attrs: {
      "src": require('@/assets/images/repricer/ozon.jpg'),
      "alt": "New Token"
    }
  })]), _c('a', {
    staticClass: "modal-right__link",
    attrs: {
      "href": "https://www.help.salesfinder.ru/repriser-token-connection",
      "target": "_blank"
    }
  }, [_vm._v(" Подробная инструкция ")])]), _c('ui-form', {
    staticStyle: {
      "padding-bottom": "35px"
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Маркетплейс")]), _c('app-select', {
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.mp,
      callback: function ($$v) {
        _vm.mp = $$v;
      },
      expression: "mp"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_c('span', {
    staticClass: "settings__block__title"
  }, [_vm.mp.id === 'wb' ? _c('span', [_vm._v(" Токен API (Методы: Статистика + Аналитика + Цены и скидки) ")]) : _c('span', [_vm._v(" Токен API (Токен с типом Admin) ")]), _c('div', {
    staticClass: "spacer"
  }), [_c('fade-transition', [!_vm.validApiKey && _vm.mayError ? _c('div', {
    staticClass: "input-error"
  }, [_vm._v(" Некорректный формат токена, ключ должен содержать " + _vm._s(_vm.tokenLength) + " символов ")]) : _vm._e()]), _vm.validApiKey && _vm.mayError ? _c('img', {
    staticClass: "input-img",
    attrs: {
      "src": require(`@/assets/images/icons/success.svg`)
    }
  }) : _vm._e(), !_vm.validApiKey && _vm.mayError ? _c('img', {
    staticClass: "input-img img-error",
    attrs: {
      "src": require(`@/assets/images/icons/close.svg`)
    }
  }) : _vm._e()], _vm.mp.id === 'wb' ? _c('Tooltip', {
    staticClass: "tooltip",
    attrs: {
      "position": "top left"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v(" Обратите внимание на правильный формат токена! ")]), _c('p', [_vm._v(" Для Wildberries нужно использовать токен с типом c методами «Статистика» + «Аналитика» + «Цены и скидки», без этого вы не сможете использовать все возможности интеграции с инструментами SalesFinder. ")])]) : _c('Tooltip', {
    staticClass: "tooltip",
    attrs: {
      "position": "top left"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v(" Обратите внимание на правильный формат токена! ")]), _c('p', [_vm._v(" Для Ozon нужно использовать токен с типом Admin, без этого вы не сможете использовать все возможности интеграции с инструментами SalesFinder. ")])])], 2)]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.apikey,
      expression: "apikey"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": _vm.tokenPlaceholder
    },
    domProps: {
      "value": _vm.apikey
    },
    on: {
      "blur": _vm.onBlurApiKey,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.apikey = $event.target.value;
      }
    }
  })]), _vm.mp.id === 'ozon' ? _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Client ID")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.client_id,
      expression: "client_id"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Например: 157984"
    },
    domProps: {
      "value": _vm.client_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.client_id = $event.target.value;
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "buttons"
  }, [_c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v(" Отмена ")]), _c('button', {
    staticClass: "btn btn-blue",
    attrs: {
      "disabled": !_vm.valid
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._v(" Подключить ")])]), _vm.showLoading ? _c('div', {
    staticClass: "loading"
  }, [_c('Spinner', {
    attrs: {
      "dark": true
    }
  }), _c('p', [_vm._v(" Выполняется проверка токена. "), _c('br'), _vm._v(" Это может занять несколько минут ")])], 1) : _vm._e(), _vm.error || _vm.hasTokens && !_vm.hasValidTokens ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Ошибка добавления магазина. Попробуйте еще раз ")]) : _vm._e()])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };