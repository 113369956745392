import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.some.js";
import AppTabs from "@/components/AppTabs.vue";
import News from "@/components/Home/News/News.vue";
import Education from "@/components/Home/Education/Education.vue";
import Dashboard from "@/components/Home/Dashboard/Dashboard.vue";
import ModalWelcome from "@/components/modals/ModalWelcomeElama.vue";
const tabsComponents = {
  news: News,
  education: Education,
  dashboard: Dashboard
};
export default {
  meta: {
    title: "Главная"
  },
  components: {
    AppTabs,
    Dashboard,
    News,
    ModalWelcome
  },
  data() {
    const tabs = [{
      id: "news",
      title: `
                <svg viewBox="0 0 32 32" height="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m5.063 19.369.521 4.602a.947.947 0 0 0 .042.197 3.006 3.006 0 0 0 3.782 1.924 3.004 3.004 0 0 0 1.924-3.781l-.83-2.551h.261l7.789 3.895a1.008 1.008 0 0 0 .972-.044.999.999 0 0 0 .476-.851v-4.142c1.721-.447 3-2 3-3.858s-1.279-3.411-3-3.858V6.76a1.001 1.001 0 0 0-1.448-.895L10.764 9.76H7c-2.757 0-5 2.243-5 5a5.006 5.006 0 0 0 3.063 4.609zM9.43 22.93a1 1 0 0 1-1.872.698l-.439-3.868h1.279l1.032 3.17zM21 14.76c0 .737-.405 1.375-1 1.722v-3.443c.595.346 1 .984 1 1.721zm-3 6.382-6-3v-6.764l6-3v12.764zM7 11.76h3v6H7c-1.654 0-3-1.346-3-3s1.346-3 3-3zM27 15.76h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2zM27 10.467a.997.997 0 0 0 .707-.293l1.414-1.414a.999.999 0 1 0-1.414-1.414L26.293 8.76A.999.999 0 0 0 27 10.467zM27.707 22.174a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-1.414-1.414a.999.999 0 1 0-1.414 1.414l1.414 1.414z"></path></svg>
                <span>Новости сервиса</span>
                `
    }, {
      id: "education",
      title: `
                <svg viewBox="0 0 32 32" height="20px" xmlns="http://www.w3.org/2000/svg"><path d="m28.82 8.69-11-6.13a3.13 3.13 0 0 0-3.58 0l-11 6.13a2.85 2.85 0 0 0 0 4.62l.11.07L7 15.44V25c0 1.57 2.26 5 9 5s9-3.43 9-5v-9.56l3-1.67V25a1 1 0 0 0 2 0V11a2.87 2.87 0 0 0-1.18-2.31ZM23 25c0 .39-1.41 3-7 3s-7-2.61-7-3v-8.45l5.2 2.89a3.17 3.17 0 0 0 3.6 0l5.2-2.89Zm4.67-13.33-11 6.12a1.14 1.14 0 0 1-1.32 0l-11-6.12a.85.85 0 0 1 0-1.34s11-6.09 11-6.12a1.17 1.17 0 0 1 1.34 0s11 6.12 11 6.12a.85.85 0 0 1 0 1.34Z" data-name="cap, graduation, hat, graduate, university, school, student, ceremony, diploma, college"></path></svg>
                <span>Обучающие материалы и видео</span>
                `
    }, {
      id: "dashboard",
      title: `
                <svg viewBox="0 0 24 24" height="20px" xmlns="http://www.w3.org/2000/svg"><path d="m20 8-6-5.26a3 3 0 0 0-4 0L4 8a3 3 0 0 0-1 2.26V19a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-8.75A3 3 0 0 0 20 8Zm-6 12h-4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1Zm5-1a1 1 0 0 1-1 1h-2v-5a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v5H6a1 1 0 0 1-1-1v-8.75a1 1 0 0 1 .34-.75l6-5.25a1 1 0 0 1 1.32 0l6 5.25a1 1 0 0 1 .34.75Z"></path></svg>
                <span>Панель инструментов</span>
                `
    }];
    const localStorageTab = tabs.find(tab => tab.id == localStorage.getItem(`homeActiveTab`));
    return {
      tabs,
      activeTab: localStorageTab ? localStorageTab : tabs[0]
    };
  },
  mounted() {
    if (this.$route.params.tab) {
      if (!this.$route.params.tab === "elama") {
        this.activeTab = this.tabs.find(tab => tab.id == this.$route.params.tab);
      } else {
        if (!this.elamaUser) {
          this.$router.push({
            name: "Dashboard",
            params: {
              tab: "news"
            }
          });
        }
      }
    } else {
      this.$router.push({
        name: "Dashboard",
        params: {
          tab: this.activeTab.id
        }
      });
    }
  },
  computed: {
    has30Tokens() {
      return this.$store.state.connections.tokens.some(item => item.status == 30);
    },
    loaded() {
      return this.$store.state.connections.loaded;
    },
    elamaUser() {
      return this.$store.state.user.user.external_service_id;
    },
    showElamaWelcomeModal() {
      return !this.has30Tokens && this.loaded && this.elamaUser && this.$route.params.tab === "elama";
    }
  },
  methods: {
    getTabComponent(id) {
      return tabsComponents[id];
    }
  },
  watch: {
    showElamaWelcomeModal(val) {
      if (val) {
        this.$refs.ModalWelcome.open();
      }
    },
    activeTab(tab, oldTab) {
      if (oldTab) {
        this.$router.push({
          name: "Dashboard",
          params: {
            tab: tab.id
          }
        });
        // console.log(tab.id);
        localStorage.setItem(`homeActiveTab`, tab.id);
      }
    }
  }
};