/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalWelcomeElama.vue?vue&type=template&id=b5ff4b28&scoped=true"
import script from "./ModalWelcomeElama.vue?vue&type=script&lang=js"
export * from "./ModalWelcomeElama.vue?vue&type=script&lang=js"
import style0 from "./ModalWelcomeElama.vue?vue&type=style&index=0&id=b5ff4b28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5ff4b28",
  null
  
)

export default component.exports