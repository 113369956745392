import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.some.js";
import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppSelect from "@/components/AppSelect.vue";
import { getMarketplacesListArray } from "@/utils/enums";
import Tooltip from "@/components/Tooltip.vue";
import uiForm from "@/components/Form/Form";
import Spinner from "@/components/Loading/Spinner.vue";
export default {
  mixins: [ModalMixin],
  components: {
    Modal,
    AppSelect,
    Tooltip,
    uiForm,
    Spinner
  },
  data() {
    const select_marketplace_items = [...getMarketplacesListArray()];
    return {
      select_marketplace_items,
      mp: select_marketplace_items[0],
      apikey: null,
      mayError: false,
      client_id: null,
      activated: false,
      loading: false,
      localClose: this.close,
      noClose: true,
      interval: true
    };
  },
  mounted() {
    this.interval = setInterval(async () => {
      await this.$store.dispatch("connections/getTokens");
    }, 5000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  methods: {
    onBlurApiKey() {
      var _this$apikey;
      this.mayError = ((_this$apikey = this.apikey) === null || _this$apikey === void 0 ? void 0 : _this$apikey.length) > 0;
    },
    async submit() {
      this.loading = true;
      try {
        if (this.mp.id === "wb") {
          this.client_id = "";
        }
        await this.$store.dispatch("connections/createToken", {
          datasource: this.mp.id,
          apikey: this.apikey,
          name: this.name,
          client_id: this.client_id
        });
        this.error = false;
      } catch (e) {
        this.error = true;
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    hasTokens() {
      return this.$store.state.connections.hasTokens;
    },
    hasValidTokens() {
      return this.$store.state.connections.tokens.some(item => [10, 30].includes(item.status));
    },
    has30Tokens() {
      return this.$store.state.connections.tokens.some(item => item.status == 30);
    },
    has10Tokens() {
      return this.$store.state.connections.tokens.some(item => item.status == 10);
    },
    showLoading() {
      return this.loading || !this.has30Tokens && this.has10Tokens;
    },
    tokenPlaceholder() {
      if (this.mp.id === "ozon") {
        return "Пример: e548756d-89f3-46gh-9f13-d602c6c0a66d";
      }
      if (this.mp.id === "wb") {
        return "Пример: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NJRCI6ImExZjRjZTQwLTA2MGYtNDZmOC1iYTc1LTIxZDE5Y2Y3MTYyOCJ9.Cab59zotwDQDddHLePVYZvCpOUy5w6y5NNvpitiOayk";
      }
      return "API Token";
    },
    tokenLength() {
      if (this.mp.id === "ozon") {
        return "36";
      }
      if (this.mp.id === "wb") {
        return "140";
      }
      return "140/36";
    },
    validApiKey() {
      if (this.mp.id === "ozon") {
        var _this$apikey2;
        return ((_this$apikey2 = this.apikey) === null || _this$apikey2 === void 0 ? void 0 : _this$apikey2.length) === 36;
      }
      if (this.mp.id === "wb") {
        var _this$apikey3;
        return ((_this$apikey3 = this.apikey) === null || _this$apikey3 === void 0 ? void 0 : _this$apikey3.length) > 140;
      }
      return false;
    },
    valid() {
      if (this.mp.id === "ozon") {
        var _this$client_id;
        return this.mp.id && ((_this$client_id = this.client_id) === null || _this$client_id === void 0 ? void 0 : _this$client_id.length) && this.validApiKey;
      }
      if (this.mp.id === "wb") {
        return this.mp.id && this.validApiKey;
      }
      return false;
    }
  },
  watch: {
    has30Tokens(val) {
      if (!val) {
        this.close = null;
        this.noClose = true;
        this.activated = false;
      } else {
        this.close = this.localClose;
        this.noClose = false;
        this.activated = true;
      }
    }
  }
};